import revive_payload_client_m3kYugldms from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.8.0_rollup@4.19.2_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Mo0g9xMMZg from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.8.0_rollup@4.19.2_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_uo8FOGMDZc from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.8.0_rollup@4.19.2_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_4GM9vtKwAe from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.8.0_rollup@4.19.2_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_mHbFHqo4Go from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.8.0_rollup@4.19.2_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_TMerC5B4rn from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.8.0_rollup@4.19.2_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_U2zHDQhDc5 from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.8.0_rollup@4.19.2_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/local/app/.nuxt/components.plugin.mjs";
import prefetch_client_RoFY7fotv1 from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.8.0_rollup@4.19.2_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_ycxNHWwOfw from "/usr/local/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_rollup@4.19.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import documentDriven_sAukm72YiZ from "/usr/local/app/node_modules/.pnpm/@nuxt+content@2.13.2_nuxt@3.12.4_rollup@4.19.2_vue@3.4.35/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import nuxt_plugin_TUnxzPzLBo from "/usr/local/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.5_rollup@4.19.2/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import provideState_hsNtY1ADO1 from "/usr/local/app/node_modules/.pnpm/nuxt-graphql-middleware@4.0.0_graphql-tag@2.12.6_graphql@16.9.0_nuxt@3.12.4_rollup@4.19.2_typescript@5.5.4_vite@5.3.5/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.mjs";
import plugin_j2wOJ2YZXU from "/usr/local/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.19.2/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import i18n_3TTsoQQTih from "/usr/local/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.19.2_vue@3.4.35/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_WZG1yt26vl from "/usr/local/app/node_modules/.pnpm/@nuxt+image@1.7.0_rollup@4.19.2/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/usr/local/app/.nuxt/unocss.mjs";
import plugin_HMUycbzStU from "/usr/local/app/modules/pino/runtime/plugin.ts";
import plugin_xDtleEcACi from "/usr/local/app/modules/gtm/runtime/plugin.ts";
import _0_graphqlConfig_yaUZblG674 from "/usr/local/app/plugins/0.graphqlConfig.ts";
import _1_auth_slaK5pfDbE from "/usr/local/app/plugins/1.auth.ts";
import _2_activeOrder_yYINElYs5H from "/usr/local/app/plugins/2.activeOrder.ts";
import collections_pRcEP3swaI from "/usr/local/app/plugins/collections.ts";
import foxentry_client_gcy3vwc779 from "/usr/local/app/plugins/foxentry.client.ts";
import scroll_client_HJUo2UKx9n from "/usr/local/app/plugins/scroll.client.ts";
import supportAgent_3y6YttNG9k from "/usr/local/app/plugins/supportAgent.ts";
import vee_validate_KcKlKmvCrJ from "/usr/local/app/plugins/vee-validate.ts";
export default [
  revive_payload_client_m3kYugldms,
  unhead_Mo0g9xMMZg,
  router_uo8FOGMDZc,
  payload_client_4GM9vtKwAe,
  navigation_repaint_client_mHbFHqo4Go,
  check_outdated_build_client_TMerC5B4rn,
  chunk_reload_client_U2zHDQhDc5,
  components_plugin_KR1HBZs4kY,
  prefetch_client_RoFY7fotv1,
  plugin_client_ycxNHWwOfw,
  documentDriven_sAukm72YiZ,
  nuxt_plugin_TUnxzPzLBo,
  provideState_hsNtY1ADO1,
  plugin_j2wOJ2YZXU,
  i18n_3TTsoQQTih,
  plugin_WZG1yt26vl,
  unocss_MzCDxu9LMj,
  plugin_HMUycbzStU,
  plugin_xDtleEcACi,
  _0_graphqlConfig_yaUZblG674,
  _1_auth_slaK5pfDbE,
  _2_activeOrder_yYINElYs5H,
  collections_pRcEP3swaI,
  foxentry_client_gcy3vwc779,
  scroll_client_HJUo2UKx9n,
  supportAgent_3y6YttNG9k,
  vee_validate_KcKlKmvCrJ
]